<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>전자결재</p>
            </div>
            


            <!-- 서브페이지 내용 -->
            <div class="con-wrap">
                <SelectClassPopup v-if="pop.isShow" @close="pop.isShow=false"></SelectClassPopup>
                <CarrotTitle title="기안작성">
                    <div class="btn-ibbox title-btnbox float-right">
                        <button class="btn-default">양식선택</button>
                        <button class="btn-default ml-10">불러오기</button>
                        <button class="btn-default ml-10">결재선</button>
                    </div>
                </CarrotTitle>
                <div class="board">
                    <div class="mt-40">
                        <div>

                            <div class="dp-table w-100per">
                                <div class="float-left w-50per pr-25"> 
                                    <table class="table-row">
                                        <tbody>
                                            <tr>
                                                <th width="150">기안자</th>
                                                <td>Min K (김민정)</td>
                                            </tr>
                                            <tr>
                                                <th width="150">소속</th>
                                                <td>솔루션개발팀</td>
                                            </tr>
                                            <tr>
                                                <th width="150">작성일</th>
                                                <td>2019-00-00</td>
                                            </tr>
                                            <tr>
                                                <th width="150">문서번호</th>
                                                <td>20190000ASD</td>
                                            </tr>
                                            <tr>
                                                <th width="150">보안</th>
                                                <td>
                                                    <label><input type="checkbox" id="Private" name="Private" value="Private"><span class="ml-5"> Private</span></label>
                                                    <div class="mt-5 color-1">
                                                        * 급여성, 인사관련사항 결재 시 체크해주세요.<br>
                                                        * Private 체크 시 회계팀 Ahri (이은주) 참조해 주세요.
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="float-right w-50per pl-25">
                                    <table class="table-col">
                                        <colgroup>
                                            <col span="5"><!--결재지정한 수만큼(thead th수)-->
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>담당</th>
                                                <th>Staff</th>
                                                <th>Staff</th>
                                                <th>팀장</th>
                                                <th>관리자</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Helen</td>
                                                <td>Helen</td>
                                                <td>Helen</td>
                                                <td>Helen</td>
                                                <td>Helen</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table class="table-row mt-45">
                                        <tbody>
                                            <tr>
                                                <th width="110" rowspan="4">참조</th>
                                                <td>솔루션개발팀</td>
                                                <td>Sophie J(정수빈)</td>
                                                <td>미확인</td>
                                            </tr>
                                            <tr>
                                                <td>솔루션개발팀</td>
                                                <td>Sophie J(정수빈)</td>
                                                <td>01/29</td>
                                            </tr>
                                            <tr>
                                                <td>솔루션개발팀</td>
                                                <td>Sophie J(정수빈)</td>
                                                <td>미확인</td>
                                            </tr>
                                            <tr>
                                                <td>솔루션개발팀</td>
                                                <td>Sophie J(정수빈)</td>
                                                <td>미확인</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <table class="table-row mt-45">
                                <tbody>
                                    <tr>
                                        <th width="150">제목</th>
                                        <td>
                                            <input type="text" class="w-100per">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table-row mt-10">
                                <colgroup>
                                    <col width="150">
                                    <col width="*">
                                    <col width="150">
                                    <col width="*">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th><span class="txt-red">*</span> 총 금액</th>
                                        <td>
                                            0
                                        </td>
                                        <th><span class="txt-red">*</span> 지출일</th>
                                        <td>
                                            <label><input type="radio" id="" name="" value=""><span class="ml-5 mr-20"> 15일</span></label>
                                            <label><input type="radio" id="" name="" value=""><span class="ml-5 mr-20"> 25일</span></label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span class="txt-red">*</span> 법인구분</th>
                                        <td colspan="3">
                                            <label><input type="radio" id="" name="" value=""><span class="ml-5 mr-20"> 캐럿글로벌</span></label>
                                            <label><input type="radio" id="" name="" value=""><span class="ml-5 mr-20"> 캐럿에듀</span></label>
                                            <label><input type="radio" id="" name="" value=""><span class="ml-5 mr-20"> 캐럿에듀플러스</span></label>
                                            <label><input type="radio" id="" name="" value=""><span class="ml-5 mr-20"> 밝음</span></label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span class="txt-red">*</span> 지출증빙</th>
                                        <td colspan="3">
                                            <label><input type="radio" id="" name="" value=""><span class="ml-5 mr-20"> 강사급여</span></label>
                                            <label><input type="radio" id="" name="" value=""><span class="ml-5 mr-20"> 교재비</span></label>
                                            <label><input type="radio" id="" name="" value=""><span class="ml-5 mr-20"> 기타경비</span></label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>특이사항</th>
                                        <td colspan="3">
                                            <textarea class="w-100per h-100px"></textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="block-title mt-50 mb-20">강사지급금액 상세</div>
                            <table class="table-row mb-10">
                                <colgroup>
                                    <col width="150">
                                    <col width="*">
                                    <col width="150">
                                    <col width="*">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>ITM 클래스</th>
                                        <td colspan="3">
                                            <div class="float-left w-85per">[na] SK가스_조승호 본부장님 1:1 어학과정 (2019-00-00~2019-00-00)</div>
                                            <button class="btn-default float-right">클래스 선택</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>매니저</th>
                                        <td>
                                            <input type="text" class="w-100per">
                                        </td>
                                        <th>강사이름</th>
                                        <td>
                                            <input type="text" class="w-100per">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span class="txt-red">*</span> 강사지급금액</th>
                                        <td>
                                            <input type="text" class="w-100per">
                                        </td>
                                        <th>출석누락일</th>
                                        <td>
                                            <input type="text" class="w-80px">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span class="txt-red">*</span> 은행/계좌번호</th>
                                        <td>
                                            <select class="w-100px">
                                                <option value="">2019</option>
                                            </select>
                                            <input type="text" class="w-215px ml-10">
                                        </td>
                                        <th><span class="txt-red">*</span> 예금주</th>
                                        <td>
                                            <input type="text" class="w-100per">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>비고(사유기재)</th>
                                        <td colspan="3">
                                            <textarea class="w-100per h-100px"></textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="dp-table w-100per">
                                <button class="btn-default float-left">+ 강사지급금액 추가</button>
                                <button class="btn-default float-right">- 강사지급금액 삭제</button>
                            </div>

                            <div class="color-1 mt-50 mb-20">※ 증빙서류 미첨부 및 작성 양식이 정확히 기재되어있지 않을 경우 지급이 지연될 수 있습니다.</div>
                            <table class="table-row">
                                <tbody>
                                    <tr>
                                        <th width="150">파일첨부</th>
                                        <td>
                                            <div>
                                                <!-- 파일첨부 전 -->
                                                <input type="file" style="display: none;">
                                                <!-- 파일첨부 후 -->
                                                <span class="btn-view mr-10">고객사일지파일.pdf</span>
                                                <button class="btn-default dp-inblock h-30px">삭제</button>
                                            </div>
                                            <div class="mt-5"><input type="file"></div>
                                            <div class="mt-5"><input type="file"></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <router-link to=""><button class="btn-default float-left mt-30">취소</button></router-link>
                            <router-link to=""><button class="btn-default float-right mt-30">등록</button></router-link>
                            <div class="clear"></div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브페이지 내용 끝 -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src 
import { reactive } from 'vue'
import SelectClassPopup from '@/components/popup/payment/SelectClassPopup.vue'


export default {
    components: {
        SelectClassPopup
    },
    setup() {
        const pop = reactive({
            // Popup 노출 여부
            isShow: true
        });
        
        return {pop};
    }
}
</script>

<style lang="scss" scoped>
</style>